import service from "./service";
import endpoint from "./constantUrl";

// History
export const getActivityHistory = (param) => {
  return service.post(
    `${endpoint.MARLIN_URL}/v1/activities/list-history`,
    param
  );
};

// Traceability History
export const getTraceabilityHistory = (param) => {
  return service.post(
    `${endpoint.TRACEABILITY_URL}/api/traceable/list-history`,
    param
  );
};

export const getTraceabilityHistoryNew = (param) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/histories/list`, param);
};
