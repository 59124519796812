import React from "react";

import Image from "../../assets/images/dev.svg";

const NoMatchPage = () => {
  return (
    <div className="border-lineStroke flex h-full flex-col items-center justify-center bg-white p-20 lg:rounded-lg lg:border">
      <img src={Image} alt="gambar" className="w-6/12" />

      <h3 className="mt-10 text-3xl font-semibold text-gray-600">404</h3>
      <p className="text-md mt-2 text-gray-600">
        This is not the web page you are looking for
      </p>
    </div>
  );
};

export default NoMatchPage;
