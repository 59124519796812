import { all, put, takeLatest, call } from "redux-saga/effects";
import { getSummary } from "../../../services/dashboard";
import { SET_STATE, GET_SUMMARY } from "./actions";

export function* GET_SUMMARY_F() {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getSummary);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(GET_SUMMARY, GET_SUMMARY_F)]);
