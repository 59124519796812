import service from "./service";
import endpoint from "./constantUrl";

// Traceable
export const getTraceable = (param) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/traceable/list`, param);
};

export const addTraceable = (data) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/traceable`, data);
};

export const getTraceableById = (id) => {
  return service.get(`${endpoint.TRACEABILITY_URL}/api/traceable/${id}`);
};

export const deleteTraceable = (id) => {
  return service.delete(`${endpoint.TRACEABILITY_URL}/api/traceable/${id}`);
};

export const downloadTemplateTraceable = () => {
  return service.getArrayBuffer(
    `${endpoint.TRACEABILITY_URL}/api/traceable/download/template`
  );
};

export const uploadTraceable = (data) => {
  return service.post(
    `${endpoint.TRACEABILITY_URL}/api/job/traceability-import`,
    data
  );
};

export const getProgressUploadTraceable = () => {
  return service.get(`${endpoint.TRACEABILITY_URL}/api/job/login`);
};

// Traceability
export const getTraceability = (data) => {
  return service.post(
    `${endpoint.TRACEABILITY_URL}/api/traceability/list`,
    data
  );
};

export const addTraceability = (data) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/traceability`, data);
};

export const getTraceabilityByCode = (code) => {
  return service.get(
    `${endpoint.TRACEABILITY_URL}/api/traceability/traceability-code/${code}`
  );
};

export const getTraceabilityById = (id) => {
  return service.get(`${endpoint.TRACEABILITY_URL}/api/traceability/${id}`);
};

export const deleteTraceability = (id) => {
  return service.delete(`${endpoint.TRACEABILITY_URL}/api/traceability/${id}`);
};

export const downloadTemplateTraceability = () => {
  return service.getArrayBuffer(
    `${endpoint.TRACEABILITY_URL}/api/traceability/download/template`
  );
};

export const uploadTraceability = (data) => {
  return service.post(
    `${endpoint.TRACEABILITY_URL}/api/job/traceability-import-trace-code`,
    data
  );
};

// Certificate
export const getCertification = (data) => {
  return service.post(
    `${endpoint.TRACEABILITY_URL}/api/certification/list`,
    data
  );
};
