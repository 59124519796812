import axios from "axios";
import {
  getObjectLocalStorage,
  setObjectLocalStorage,
  removeLocalStorage
} from "../utils";
import endpoint from "./constantUrl";

class Service {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;

    this.auth = getObjectLocalStorage("auth");
    this.user = getObjectLocalStorage("user");
    this.refreshTokenCount = 0;
    this.refreshTokenInterval = 1000;

    // this.user?.loginType;
    this.handleSetToken(this.auth);
  }

  handleSetToken(auth) {
    this.token = auth?.access_token;
    this.token_wms = auth?.wms_token;
    this.g_code = auth?.code;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = async (error) => {
    console.log("-ERROR-", error);
    // handle network error
    if (
      error?.message === "Network request failed" ||
      error?.message === "Network Error"
    ) {
      alert(
        "Network Error!",
        "Silakan periksa koneksi Anda dan coba kembali.",
        "iconNoInet"
      );
    }

    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      this.g_code === undefined &&
      !originalRequest._retry
    ) {
      this.refreshTokenCount += 1;
      originalRequest._retry = true;
      const refreshToken = async () => {
        try {
          const response = await this.service.request({
            method: "POST",
            url: `${endpoint.MARLIN_URL}/v1/auth/refresh`,
            responseType: "json",
            data: {
              refresh_token: this.auth.refresh_token
            }
          });
          setInterval(() => {
            this.refreshTokenInterval += 1000;
          }, this.refreshTokenInterval);

          return response.data.data.auth;
        } catch (error) {
          console.error("Token refresh failed:", error);
          removeLocalStorage();
        }
      };
      if (this.refreshTokenCount == 1) {
        const auth = await refreshToken();
        originalRequest.headers["Authorization"] =
          `Bearer ${auth?.access_token}`;
        this.setInterval = 0;
        setObjectLocalStorage("auth", auth);
        this.handleSetToken(auth);
        return this.service(originalRequest);
      } else {
        const makeRequestWithRefreshedToken = async (oR) => {
          return new Promise((resolve, reject) => {
            setTimeout(async () => {
              try {
                oR.headers["Authorization"] = `Bearer ${this.token}`;
                const response = await this.service(oR);
                resolve(response);
              } catch (error) {
                reject(error);
              }
            }, this.refreshTokenInterval);
          });
        };
        return await makeRequestWithRefreshedToken(originalRequest);
      }
    }

    return Promise.reject(error);
  };

  get(path, params, callback) {
    return this.service
      .get(path, {
        params,
        headers: {
          Authorization: `Bearer ${this.token}`,
          "G-code": this.g_code
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getArrayBuffer(path, params, callback) {
    return this.service
      .get(path, {
        params,
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${this.token}`,
          "G-code": this.g_code
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  patch(path, payload, callback) {
    return this.service
      .request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  put(put, payload, callback) {
    return this.service
      .request({
        method: "PUT",
        url: put,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  post(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `Bearer ${this.token}`,
          "G-code": this.g_code
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postWMS(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `Token ${this.token_wms}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postWithoutHeader(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postMultipart(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        data: payload,
        headers: {
          "x-private": "false",
          Authorization: `Token ${this.auth.wms_token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  delete(path, payload, callback) {
    return this.service
      .request({
        method: "DELETE",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }
}

export default new Service();
