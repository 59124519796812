import React from "react";

function Dashboard({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="20" height="20" fill="none" />
      <path
        d="M3 4.55556C3 4.143 3.1475 3.74733 3.41005 3.45561C3.6726 3.16389 4.0287 3 4.4 3H8.6V17H4.4C4.0287 17 3.6726 16.8361 3.41005 16.5444C3.1475 16.2527 3 15.857 3 15.4444V4.55556ZM11.4 3H15.6C15.9713 3 16.3274 3.16389 16.5899 3.45561C16.8525 3.74733 17 4.143 17 4.55556V8.44444H11.4V3ZM11.4 11.5556H17V15.4444C17 15.857 16.8525 16.2527 16.5899 16.5444C16.3274 16.8361 15.9713 17 15.6 17H11.4V11.5556Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Dashboard;
