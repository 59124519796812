import service from "./service";
import endpoint from "./constantUrl";

// Company
export const getCompany = (param) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/companies/list`, param);
};

export const getCompanyById = (id) => {
  return service.get(`${endpoint.TRACEABILITY_URL}/api/companies/${id}`);
};

export const addCompany = (data) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/companies`, data);
};

export const updateCompany = (data, id) => {
  return service.put(`${endpoint.TRACEABILITY_URL}/api/companies/${id}`, data);
};

export const deleteCompany = (id) => {
  return service.delete(`${endpoint.TRACEABILITY_URL}/api/companies/${id}`);
};
