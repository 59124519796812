import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  getActivityOverview,
  getActivity,
  addActivity,
  getActivityById
} from "../../../services/activity";
import {
  SET_STATE,
  GET_ACTIVITY_OVERVIEW,
  GET_ACTIVITY,
  ADD_ACTIVITY,
  GET_ACTIVITY_BY_ID
} from "./actions";
import { setObjectLocalStorage } from "../../../utils";

export function* GET_ACTIVITY_OVERVIEW_F() {
  yield put({
    type: SET_STATE,
    payload: { loadingOverview: true, dataOverview: null }
  });
  try {
    const { data } = yield call(getActivityOverview);
    yield put({ type: SET_STATE, payload: { dataOverview: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingOverview: false } });
  }
}

export function* GET_ACTIVITY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getActivity, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_ACTIVITY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(addActivity, action.data);
    setObjectLocalStorage("message", data.data);

    yield put({
      type: SET_STATE,
      payload: { isAdd: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_ACTIVITY_BY_ID_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getActivityById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_ACTIVITY_OVERVIEW, GET_ACTIVITY_OVERVIEW_F),
  takeLatest(GET_ACTIVITY, GET_ACTIVITY_F),
  takeLatest(ADD_ACTIVITY, ADD_ACTIVITY_F),
  takeLatest(GET_ACTIVITY_BY_ID, GET_ACTIVITY_BY_ID_F)
]);
