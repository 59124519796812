import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "history/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_ACTIVITY_HISTORY = "GET_ACTIVITY_HISTORY";
export const getActivityHistory = defaultActionCreator(
  GET_ACTIVITY_HISTORY,
  "param"
);

export const GET_TRACEABILITY_HISTORY = "GET_TRACEABILITY_HISTORY";
export const getTraceabilityHistory = defaultActionCreator(
  GET_TRACEABILITY_HISTORY,
  "param"
);
