import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  getCompany,
  getCompanyById,
  addCompany,
  updateCompany,
  deleteCompany
} from "../../../services/company";
import {
  SET_STATE,
  GET_COMPANY,
  GET_COMPANY_BY_ID,
  ADD_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY
} from "./actions";

export function* GET_COMPANY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingList: true } });
  try {
    const { data } = yield call(getCompany, action.param);
    yield put({ type: SET_STATE, payload: { list: data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingList: false } });
  }
}

export function* GET_COMPANY_BY_ID_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getCompanyById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_COMPANY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(addCompany, action.data);

    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* UPDATE_COMPANY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(updateCompany, action.data, action.id);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* DELETE_COMPANY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(deleteCompany, action.id);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export default all([
  takeLatest(GET_COMPANY, GET_COMPANY_F),
  takeLatest(GET_COMPANY_BY_ID, GET_COMPANY_BY_ID_F),
  takeLatest(ADD_COMPANY, ADD_COMPANY_F),
  takeLatest(UPDATE_COMPANY, UPDATE_COMPANY_F),
  takeLatest(DELETE_COMPANY, DELETE_COMPANY_F)
]);
