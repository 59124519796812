import service from "./service";
import endpoint from "./constantUrl";

export const getUserLevel = (param) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/user-level/list`, param);
};

export const getUserLevelById = (id) => {
  return service.get(`${endpoint.MARLIN_URL}/v1/user-level/` + id);
};

export const addUserLevel = (data) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/user-level`, data);
};

export const updateUserLevel = (data, uuid) => {
  return service.put(`${endpoint.MARLIN_URL}/v1/user-level/` + uuid, data);
};

export const deleteUserLevel = (uuid) => {
  return service.delete(`${endpoint.MARLIN_URL}/v1/user-level/` + uuid);
};
