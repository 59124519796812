import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  login,
  createTokenForgotPassword,
  verifyTokenForgotPassword,
  submitForgotPassword,
  verifyTokenUserCreation,
  submitUserCreation
} from "../../../services/auth";
import {
  SET_STATE,
  LOGIN,
  CREATE_TOKEN_FORGOT_PASSWORD,
  VERIFY_TOKEN_FORGOT_PASSWORD,
  SUBMIT_FORGOT_PASSWORD,
  VERIFY_TOKEN_USER_CREATION,
  SUBMIT_USER_CREATION
} from "./actions";
import { setObjectLocalStorage } from "../../../utils";

export function* LOGIN_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(login, action.data);
    const dataUser = data.data.user;
    setObjectLocalStorage("auth", data.data.auth);
    setObjectLocalStorage("user", {
      ...dataUser,
      name: dataUser.name,
      email: dataUser.email,
      user_role: dataUser.role_code,
      loginType: action.data.choose
    });
    yield put({ type: SET_STATE, payload: { data } });

    const pathBefore = localStorage.getItem("pathBefore");
    if (pathBefore) {
      yield (window.location.href = pathBefore);
    } else {
      if (action.data.choose === "blockchain") {
        yield (window.location.href = "/blockchain/dashboard");
      } else if (action.data.choose === "traceability") {
        yield (window.location.href = "/traceability/dashboard");
      }
    }
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* CREATE_TOKEN_FORGOT_PASSWORD_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(createTokenForgotPassword, action.data);
    yield put({
      type: SET_STATE,
      payload: { isCreateToken: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* VERIFY_TOKEN_FORGOT_PASSWORD_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(verifyTokenForgotPassword, action.data);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* SUBMIT_FORGOT_PASSWORD_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(submitForgotPassword, action.data);
    setObjectLocalStorage("message", {
      message: "Your password has been reset"
    });

    yield put({
      type: SET_STATE,
      payload: { isSubmitForgotPassword: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* VERIFY_TOKEN_USER_CREATION_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(verifyTokenUserCreation, action.data);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* SUBMIT_USER_CREATION_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(submitUserCreation, action.data);
    setObjectLocalStorage("message", {
      message: "Your password has been created"
    });

    yield put({
      type: SET_STATE,
      payload: { isSubmitUserCreation: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(LOGIN, LOGIN_F),
  takeLatest(CREATE_TOKEN_FORGOT_PASSWORD, CREATE_TOKEN_FORGOT_PASSWORD_F),
  takeLatest(VERIFY_TOKEN_FORGOT_PASSWORD, VERIFY_TOKEN_FORGOT_PASSWORD_F),
  takeLatest(SUBMIT_FORGOT_PASSWORD, SUBMIT_FORGOT_PASSWORD_F),
  takeLatest(VERIFY_TOKEN_USER_CREATION, VERIFY_TOKEN_USER_CREATION_F),
  takeLatest(SUBMIT_USER_CREATION, SUBMIT_USER_CREATION_F)
]);
