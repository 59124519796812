import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "company/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_COMPANY = "GET_COMPANY";
export const getCompany = defaultActionCreator(GET_COMPANY, "param");

export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const getCompanyById = defaultActionCreator(GET_COMPANY_BY_ID, "id");

export const ADD_COMPANY = "ADD_COMPANY";
export const addCompany = defaultActionCreator(ADD_COMPANY, "data");

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const updateCompany = defaultActionCreator(UPDATE_COMPANY, "data", "id");

export const DELETE_COMPANY = "DELETE_COMPANY";
export const deleteCompany = defaultActionCreator(DELETE_COMPANY, "id");
