import React from "react";

function Contract({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1092_149)">
        <path
          d="M3.33331 3.33329C3.33331 2.89127 3.50891 2.46734 3.82147 2.15478C4.13403 1.84222 4.55795 1.66663 4.99998 1.66663H11.6666C11.8876 1.66667 12.0996 1.7545 12.2558 1.91079L16.4225 6.07746C16.5788 6.2337 16.6666 6.44563 16.6666 6.66663V16.6666C16.6666 17.1087 16.4911 17.5326 16.1785 17.8451C15.8659 18.1577 15.442 18.3333 15 18.3333H4.99998C4.55795 18.3333 4.13403 18.1577 3.82147 17.8451C3.50891 17.5326 3.33331 17.1087 3.33331 16.6666V3.33329ZM14.655 6.66663L11.6666 3.67829V6.66663H14.655ZM9.99998 3.33329H4.99998V16.6666H15V8.33329H10.8333C10.6123 8.33329 10.4003 8.2455 10.2441 8.08922C10.0878 7.93293 9.99998 7.72097 9.99998 7.49996V3.33329Z"
          fill="currentColor"
        />
        <circle cx="16" cy="16" r="7" fill="white" />
        <path
          d="M11.6112 12.9647L11.5667 12.9944V13.0479V18.3952C11.5667 18.523 11.6175 18.6456 11.7078 18.736C11.7982 18.8263 11.9208 18.8771 12.0486 18.8771H18.1597C18.2876 18.8771 18.4101 18.8263 18.5005 18.736C18.5909 18.6456 18.6417 18.523 18.6417 18.3952V13.0479V12.9944L18.5972 12.9647L15.3717 10.8144C15.3717 10.8144 15.3716 10.8144 15.3716 10.8144C15.2924 10.7616 15.1994 10.7334 15.1042 10.7334C15.009 10.7334 14.9159 10.7616 14.8367 10.8144C14.8367 10.8144 14.8367 10.8144 14.8367 10.8144L11.6112 12.9647ZM17.6778 17.9132H12.5306V13.5101L15.1042 11.7947L17.6778 13.5101V17.9132ZM14.4933 14.4227C14.6553 14.5847 14.8751 14.6757 15.1042 14.6757C15.3333 14.6757 15.553 14.5847 15.715 14.4227C15.8771 14.2607 15.9681 14.041 15.9681 13.8118C15.9681 13.5827 15.8771 13.363 15.715 13.201C15.553 13.039 15.3333 12.9479 15.1042 12.9479C14.8751 12.9479 14.6553 13.039 14.4933 13.201C14.3313 13.363 14.2403 13.5827 14.2403 13.8118C14.2403 14.041 14.3313 14.2607 14.4933 14.4227Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1092_149">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Contract;
