import React from "react";

function Profile({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M45 7.5C41.2916 7.5 37.6665 8.59967 34.5831 10.6599C31.4996 12.7202 29.0964 15.6486 27.6773 19.0747C26.2581 22.5008 25.8868 26.2708 26.6103 29.9079C27.3337 33.5451 29.1195 36.886 31.7417 39.5083C34.364 42.1305 37.7049 43.9163 41.3421 44.6397C44.9792 45.3632 48.7492 44.9919 52.1753 43.5727C55.6014 42.1536 58.5298 39.7504 60.5901 36.6669C62.6503 33.5835 63.75 29.9584 63.75 26.25C63.75 21.2772 61.7746 16.5081 58.2583 12.9917C54.7419 9.47544 49.9728 7.5 45 7.5ZM45 37.5C42.775 37.5 40.5999 36.8402 38.7498 35.604C36.8998 34.3679 35.4578 32.6109 34.6064 30.5552C33.7549 28.4995 33.5321 26.2375 33.9662 24.0552C34.4002 21.8729 35.4717 19.8684 37.045 18.295C38.6184 16.7217 40.6229 15.6503 42.8052 15.2162C44.9875 14.7821 47.2495 15.0049 49.3052 15.8564C51.3609 16.7078 53.1179 18.1498 54.354 19.9998C55.5902 21.8499 56.25 24.025 56.25 26.25C56.25 29.2337 55.0647 32.0952 52.955 34.205C50.8452 36.3147 47.9837 37.5 45 37.5ZM78.75 78.75V75C78.75 68.0381 75.9844 61.3613 71.0616 56.4384C66.1387 51.5156 59.4619 48.75 52.5 48.75H37.5C30.5381 48.75 23.8613 51.5156 18.9384 56.4384C14.0156 61.3613 11.25 68.0381 11.25 75V78.75H18.75V75C18.75 70.0272 20.7254 65.2581 24.2417 61.7417C27.7581 58.2254 32.5272 56.25 37.5 56.25H52.5C57.4728 56.25 62.2419 58.2254 65.7583 61.7417C69.2746 65.2581 71.25 70.0272 71.25 75V78.75H78.75Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Profile;
