import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "user/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_USER_SUPPLIER_LIST = "GET_USER_SUPPLIER_LIST";
export const getUserSupplierList = defaultActionCreator(
  GET_USER_SUPPLIER_LIST,
  "param"
);

export const GET_USER = "GET_USER";
export const getUser = defaultActionCreator(GET_USER, "param");

export const GET_USER_BY_UUID = "GET_USER_BY_UUID";
export const getUserByUUID = defaultActionCreator(GET_USER_BY_UUID, "uuid");

export const ADD_USER = "ADD_USER";
export const addUser = defaultActionCreator(ADD_USER, "data");

export const UPDATE_USER = "UPDATE_USER";
export const updateUser = defaultActionCreator(UPDATE_USER, "data", "uuid");

export const DELETE_USER = "DELETE_USER";
export const deleteUser = defaultActionCreator(DELETE_USER, "uuid");

export const ACTIVATE_OR_DEACTIVATE_USER = "ACTIVATE_OR_DEACTIVATE_USER";
export const activateOrDeactivateUser = defaultActionCreator(
  ACTIVATE_OR_DEACTIVATE_USER,
  "uuid"
);

export const CHANGE_PHOTO_USER = "CHANGE_PHOTO_USER";
export const changePhotoUser = defaultActionCreator(CHANGE_PHOTO_USER, "data");
