import service from "./service";
import endpoint from "./constantUrl";

// User
export const getUserTraceabilityList = (param) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/users/list`, param);
};

export const getUserTraceabilityByUUID = (uuid) => {
  return service.get(`${endpoint.TRACEABILITY_URL}/api/users/` + uuid);
};

export const getUserTraceabilityByEmail = (email) => {
  return service.get(`${endpoint.TRACEABILITY_URL}/api/users/email/` + email);
};

export const addUserTraceability = (data) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/users`, data);
};

export const updateUserTraceability = (data, uuid) => {
  return service.put(`${endpoint.TRACEABILITY_URL}/api/users/` + uuid, data);
};

export const deleteUserTraceability = (uuid) => {
  return service.delete(`${endpoint.TRACEABILITY_URL}/api/users/` + uuid);
};

export const changePasswordUserTraceability = (data, uuid) => {
  return service.post(
    `${endpoint.TRACEABILITY_URL}/api/users/change-password/` + uuid,
    data
  );
};

// Role
export const getRoleTraceabilityList = (param) => {
  return service.post(`${endpoint.TRACEABILITY_URL}/api/roles/list`, param);
};
