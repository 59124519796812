import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "contract/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_CONTRACT = "GET_CONTRACT";
export const getContract = defaultActionCreator(GET_CONTRACT, "param");

export const GET_CONTRACT_BY_ID = "GET_CONTRACT_BY_ID";
export const getContractById = defaultActionCreator(GET_CONTRACT_BY_ID, "id");

export const GET_ALL_DOC = "GET_ALL_DOC";
export const getAllDoc = defaultActionCreator(GET_ALL_DOC, "id");
