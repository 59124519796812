import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import io from "socket.io-client";

// Routes
import { routeWithoutAuth, routeBlockchain, routeTraceability } from "./routes";

// Components
import Layout from "./components/layouts";
import NoMatchPage from "./pages/noMatchPage";
import LoadingPage from "./pages/loadingPage";

// Services
import { setState } from "./store/reducers/general/actions";

// Utils
import { getObjectLocalStorage } from "./utils";
import { ENDPOINT } from "./utils/constants";

function App() {
  const dispatch = useDispatch();
  const auth = getObjectLocalStorage("auth");
  const user = getObjectLocalStorage("user");

  const socket = io(`${ENDPOINT.MARLIN_URL}/notifications`, {
    extraHeaders: {
      Authorization: `Bearer ${auth?.access_token}`
    },
    query: {
      search: "",
      page: 1,
      per_page: 100
    }
  });

  useEffect(() => {
    if (auth && user?.loginType === "blockchain") {
      socket.on("list", (newNotif) => {
        dispatch(setState({ listNotif: newNotif }));
      });

      socket.on("count-unread", (newCountRead) => {
        dispatch(setState({ countUnread: newCountRead }));
      });

      socket.on("connect_error", (error) => {
        if (error) {
          const [message, statusCode] = error.message.split(":");
          console.error("Received error from the server:", message);
          console.error("Status code:", statusCode);
        }
      });
    }
  }, []);

  const handleOpenNotif = () => {
    socket.emit("read-badge");
  };

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        {user?.loginType === "blockchain" ? (
          <Route
            path="/"
            element={<Layout handleOpenNotif={handleOpenNotif} />}
          >
            {routeBlockchain.map((item, key) => (
              <Route
                key={key}
                element={
                  <item.component
                    {...{
                      token: auth?.access_token
                    }}
                  />
                }
                state={item.name}
                path={item.path}
              />
            ))}
          </Route>
        ) : user?.loginType === "traceability" ? (
          <Route path="/" element={<Layout />}>
            {routeTraceability.map((item, key) => (
              <Route
                key={key}
                element={
                  <item.component
                    {...{
                      token: auth?.access_token
                    }}
                  />
                }
                state={item.name}
                path={item.path}
              />
            ))}
          </Route>
        ) : (
          routeWithoutAuth.map((item, key) => (
            <Route key={key} element={<item.component />} path={item.path} />
          ))
        )}

        <Route path="*" element={<Layout />}>
          <Route element={<NoMatchPage />} path="*" />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
