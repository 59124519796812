import { all, put, takeLatest, call } from "redux-saga/effects";
import { getTradesByEvent } from "../../../services/trade";
import { SET_STATE, GET_TRADES_BY_EVENT } from "./actions";

export function* GET_TRADES_BY_EVENT_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTradesByEvent, action.param);
    yield put({ type: SET_STATE, payload: { dataTrade: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(GET_TRADES_BY_EVENT, GET_TRADES_BY_EVENT_F)]);
