import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  getUserTraceabilityList,
  getUserTraceabilityByUUID,
  getUserTraceabilityByEmail,
  addUserTraceability,
  updateUserTraceability,
  deleteUserTraceability,
  changePasswordUserTraceability,
  getRoleTraceabilityList
} from "../../../services/traceabilityAccount";
import {
  SET_STATE,
  GET_USER_TRACEABILITY,
  GET_USER_TRACEABILITY_BY_UUID,
  GET_USER_TRACEABILITY_BY_EMAIL,
  ADD_USER_TRACEABILITY,
  UPDATE_USER_TRACEABILITY,
  DELETE_USER_TRACEABILITY,
  CHANGE_PASSWORD_USER_TRACEABILITY,
  GET_ROLE_TRACEABILITY
} from "./actions";

export function* GET_USER_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingList: true } });
  try {
    const { data } = yield call(getUserTraceabilityList, action.param);
    yield put({ type: SET_STATE, payload: { list: data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingList: false } });
  }
}

export function* GET_USER_TRACEABILITY_BY_UUID_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getUserTraceabilityByUUID, action.uuid);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_USER_TRACEABILITY_BY_EMAIL_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getUserTraceabilityByEmail, action.email);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_USER_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    yield call(addUserTraceability, action.data);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: "success add user" }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* UPDATE_USER_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    yield call(updateUserTraceability, action.data, action.uuid);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: "success update user" }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* DELETE_USER_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(deleteUserTraceability, action.uuid);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* CHANGE_PASSWORD_USER_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    yield call(changePasswordUserTraceability, action.data, action.uuid);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: "success change password user" }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* GET_ROLE_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingRole: true } });
  try {
    const { data } = yield call(getRoleTraceabilityList, action.param);
    yield put({ type: SET_STATE, payload: { role: data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingRole: false } });
  }
}

export default all([
  takeLatest(GET_USER_TRACEABILITY, GET_USER_TRACEABILITY_F),
  takeLatest(GET_USER_TRACEABILITY_BY_UUID, GET_USER_TRACEABILITY_BY_UUID_F),
  takeLatest(GET_USER_TRACEABILITY_BY_EMAIL, GET_USER_TRACEABILITY_BY_EMAIL_F),
  takeLatest(ADD_USER_TRACEABILITY, ADD_USER_TRACEABILITY_F),
  takeLatest(UPDATE_USER_TRACEABILITY, UPDATE_USER_TRACEABILITY_F),
  takeLatest(DELETE_USER_TRACEABILITY, DELETE_USER_TRACEABILITY_F),
  takeLatest(
    CHANGE_PASSWORD_USER_TRACEABILITY,
    CHANGE_PASSWORD_USER_TRACEABILITY_F
  ),
  takeLatest(GET_ROLE_TRACEABILITY, GET_ROLE_TRACEABILITY_F)
]);
