import { all, put, takeLatest, call } from "redux-saga/effects";
import { addHelp } from "../../../services/help";
import { SET_STATE, ADD_HELP } from "./actions";

export function* ADD_HELP_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addHelp, action.data);
    let toast = {
      message: "Your Message Has Been Submitted",
      variant: "success"
    };
    yield put({
      type: SET_STATE,
      payload: { isAdd: true, toast }
    });
  } catch (error) {
    let toast = {
      message: "Error When Submit Your Message",
      variant: "failed"
    };
    yield put({
      type: SET_STATE,
      payload: { error: error.response.data, toast }
    });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(ADD_HELP, ADD_HELP_F)]);
