import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "trade/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_TRADES_BY_EVENT = "GET_TRADES_BY_EVENT";
export const getTradesByEvent = defaultActionCreator(
  GET_TRADES_BY_EVENT,
  "param"
);
