import { all, put, takeLatest, call } from "redux-saga/effects";
import * as actions from "./actions";
import * as services from "../../../services/role";

export function* GET_ROLE_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingList: true } });
  try {
    const { data } = yield call(services.getRole, action.param);
    yield put({ type: actions.SET_STATE, payload: { list: data } });
  } catch ({ response }) {
    yield put({ type: actions.SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingList: false } });
  }
}

export function* GET_ROLE_BY_ID_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(services.getRoleById, action.id);
    yield put({ type: actions.SET_STATE, payload: { data } });
  } catch ({ response }) {
    yield put({ type: actions.SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_ROLE_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(services.addRole, action.data);
    yield put({
      type: actions.SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: { error: error.response.data }
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* UPDATE_ROLE_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(services.updateRole, action.data, action.id);
    yield put({
      type: actions.SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: { error: error.response.data }
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* DELETE_ROLE_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(services.deleteRole, action.id);
    yield put({
      type: actions.SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: { error: error.response.data }
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingChanges: false } });
  }
}

export default all([
  takeLatest(actions.GET_ROLE, GET_ROLE_F),
  takeLatest(actions.GET_ROLE_BY_ID, GET_ROLE_BY_ID_F),
  takeLatest(actions.ADD_ROLE, ADD_ROLE_F),
  takeLatest(actions.UPDATE_ROLE, UPDATE_ROLE_F),
  takeLatest(actions.DELETE_ROLE, DELETE_ROLE_F)
]);
