import React from "react";

// Assets
import FishlogLogo from "../../assets/images/fishlog-logo.svg";
import "../../assets/styles/loading.scss";

const LoadingPage = () => {
  return (
    <div className="min-w-screen flex h-screen min-h-screen w-screen items-center justify-center bg-white">
      <div className="loading-wrapper">
        <div className="loading-element">
          <span></span>
        </div>
        <div className="loading-element">
          <h1 className=" text-2xl font-SemiBold text-NetralTypography">
            FishLog Trace Management System
          </h1>
        </div>
        <div className="loading-element">
          <h1 className=" text-2xl font-SemiBold text-NetralTypography">
            Traceable seafood supply enabled by blockchain
          </h1>
        </div>
        <div className="loading-element">
          <img src={FishlogLogo} alt="fishlog logo" />
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
