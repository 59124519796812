import service from "./service";
import endpoint from "./constantUrl";

// Catching Area
export const getCatchingArea = (param) => {
  return service.post(
    `${endpoint.TRACEABILITY_URL}/api/catching-area/list`,
    param
  );
};
