import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "activity/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_ACTIVITY_OVERVIEW = "GET_ACTIVITY_OVERVIEW";
export const getActivityOverview = defaultActionCreator(GET_ACTIVITY_OVERVIEW);

export const GET_ACTIVITY = "GET_ACTIVITY";
export const getActivity = defaultActionCreator(GET_ACTIVITY, "param");

export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const addActivity = defaultActionCreator(ADD_ACTIVITY, "data");

export const GET_ACTIVITY_BY_ID = "GET_ACTIVITY_BY_ID";
export const getActivityById = defaultActionCreator(GET_ACTIVITY_BY_ID, "id");
