import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "traceabilityAccount/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_USER_TRACEABILITY = "GET_USER_TRACEABILITY";
export const getUserTraceability = defaultActionCreator(
  GET_USER_TRACEABILITY,
  "param"
);

export const GET_USER_TRACEABILITY_BY_UUID = "GET_USER_TRACEABILITY_BY_UUID";
export const getUserTraceabilityByUUID = defaultActionCreator(
  GET_USER_TRACEABILITY_BY_UUID,
  "uuid"
);

export const GET_USER_TRACEABILITY_BY_EMAIL = "GET_USER_TRACEABILITY_BY_EMAIL";
export const getUserTraceabilityByEMAIL = defaultActionCreator(
  GET_USER_TRACEABILITY_BY_EMAIL,
  "email"
);

export const ADD_USER_TRACEABILITY = "ADD_USER_TRACEABILITY";
export const addUserTraceability = defaultActionCreator(
  ADD_USER_TRACEABILITY,
  "data"
);

export const UPDATE_USER_TRACEABILITY = "UPDATE_USER_TRACEABILITY";
export const updateUserTraceability = defaultActionCreator(
  UPDATE_USER_TRACEABILITY,
  "data",
  "uuid"
);

export const DELETE_USER_TRACEABILITY = "DELETE_USER_TRACEABILITY";
export const deleteUserTraceability = defaultActionCreator(
  DELETE_USER_TRACEABILITY,
  "uuid"
);

export const CHANGE_PASSWORD_USER_TRACEABILITY =
  "CHANGE_PASSWORD_USER_TRACEABILITY";
export const changePasswordUserTraceability = defaultActionCreator(
  CHANGE_PASSWORD_USER_TRACEABILITY,
  "data",
  "uuid"
);

export const GET_ROLE_TRACEABILITY = "GET_ROLE_TRACEABILITY";
export const getRoleTraceability = defaultActionCreator(
  GET_ROLE_TRACEABILITY,
  "param"
);
