import { all } from "redux-saga/effects";

// Import sagas from reducer
import auth from "../reducers/auth/sagas";
import history from "../reducers/history/sagas";
import activity from "../reducers/activity/sagas";
import contract from "../reducers/contract/sagas";
import trade from "../reducers/trade/sagas";
import user from "../reducers/user/sagas";
import help from "../reducers/help/sagas";
import guest from "../reducers/guest/sagas";
import traceability from "../reducers/traceability/sagas";
import catchingArea from "../reducers/catchingArea/sagas";
import role from "../reducers/role/sagas";
import userLevel from "../reducers/userLevel/sagas";
import dashboard from "../reducers/dashboard/sagas";
import traceabilityAccount from "../reducers/traceabilityAccount/sagas";
import company from "../reducers/company/sagas";

export default function* rootSaga() {
  yield all([
    // List sagas
    auth,
    history,
    activity,
    contract,
    trade,
    user,
    help,
    guest,
    traceability,
    catchingArea,
    role,
    userLevel,
    dashboard,
    traceabilityAccount,
    company
  ]);
}
