import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "auth/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const LOGIN = "LOGIN";
export const login = defaultActionCreator(LOGIN, "data");

export const CREATE_TOKEN_FORGOT_PASSWORD = "CREATE_TOKEN_FORGOT_PASSWORD";
export const createTokenForgotPassword = defaultActionCreator(
  CREATE_TOKEN_FORGOT_PASSWORD,
  "data"
);

export const VERIFY_TOKEN_FORGOT_PASSWORD = "VERIFY_TOKEN_FORGOT_PASSWORD";
export const verifyTokenForgotPassword = defaultActionCreator(
  VERIFY_TOKEN_FORGOT_PASSWORD,
  "data"
);

export const SUBMIT_FORGOT_PASSWORD = "SUBMIT_FORGOT_PASSWORD";
export const submitForgotPassword = defaultActionCreator(
  SUBMIT_FORGOT_PASSWORD,
  "data"
);

export const VERIFY_TOKEN_USER_CREATION = "VERIFY_TOKEN_USER_CREATION";
export const verifyTokenUserCreation = defaultActionCreator(
  VERIFY_TOKEN_USER_CREATION,
  "data"
);

export const SUBMIT_USER_CREATION = "SUBMIT_USER_CREATION";
export const submitUserCreation = defaultActionCreator(
  SUBMIT_USER_CREATION,
  "data"
);
