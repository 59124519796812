import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// Components
import Header from "./header";
import Sidebar from "./sidebar";

// Utils
import { getObjectLocalStorage } from "../../utils";

const Layout = ({ handleOpenNotif }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getObjectLocalStorage("auth");

  const [openMenu, setOpenMenu] = useState(true);

  useEffect(() => {
    if (auth == null) {
      navigate("/login");
      localStorage.setItem("pathBefore", location.pathname);
    } else {
      localStorage.removeItem("pathBefore");
    }
  }, [auth]);

  useEffect(() => {
    if (
      location.pathname === "/blockchain/profile" ||
      location.pathname === "/blockchain/help"
    ) {
      setOpenMenu(false);
    }
  }, [location]);

  return (
    <div>
      {/* Header */}
      <Header
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        handleOpenNotif={handleOpenNotif}
      />

      <div className="h-full w-full bg-Background">
        {/* Sidebar */}
        <div
          className={`fixed z-30 min-h-screen w-[257px] bg-white px-[22px] pt-24 drop-shadow-md transition-all duration-500 lg:pt-24 lg:drop-shadow-none ${
            openMenu ? "-left-[257px] lg:left-0" : "left-0 lg:-left-[257px]"
          }`}
        >
          <Sidebar />
        </div>

        {/* Content */}
        <div
          className={`min-h-screen w-full px-5 pt-24 transition-all duration-500 lg:pb-12 lg:pt-28 ${
            openMenu ? "lg:pl-[289px] lg:pr-8" : "lg:pl-8 lg:pr-8"
          }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
