import { all, put, takeLatest, call } from "redux-saga/effects";
import { requestGuestCode, getGuestCode } from "../../../services/guest";
import { SET_STATE, REQUEST_GUEST_CODE, GET_GUEST_CODE } from "./actions";
import { setObjectLocalStorage } from "../../../utils";

export function* REQUEST_GUEST_CODE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(requestGuestCode, action.data);
    setObjectLocalStorage("message", {
      message: "Name is submitted, our admin will contact you shortly"
    });

    yield put({
      type: SET_STATE,
      payload: { isRequestGuestCode: true, message: data.message }
    });
    yield (window.location.href = "/guest/verify");
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GUEST_CODE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getGuestCode, action.code);
    yield put({ type: SET_STATE, payload: data });
    setObjectLocalStorage("auth", data.data);

    yield (window.location.href = "/dashboard");
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(REQUEST_GUEST_CODE, REQUEST_GUEST_CODE_F),
  takeLatest(GET_GUEST_CODE, GET_GUEST_CODE_F)
]);
