import service from "./service";
import endpoint from "./constantUrl";

// Contract
export const getContract = (param) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/contracts/list`, param);
};

export const getContractById = (id) => {
  return service.get(`${endpoint.MARLIN_URL}/v1/contracts/${id}`);
};

export const getAllDoc = (id) => {
  return service.get(`${endpoint.MARLIN_URL}/v1/contracts/all-doc/${id}`);
};
