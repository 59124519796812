import React from "react";

function History({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 6.66667V10L11.6667 11.6667M2.54175 9.16672C2.72849 7.33344 3.58363 5.63307 4.94403 4.39003C6.30442 3.14699 8.07483 2.44829 9.91748 2.42725C11.7601 2.4062 13.546 3.06428 14.9345 4.27592C16.3229 5.48756 17.2167 7.16796 17.4452 8.9965C17.6738 10.825 17.2212 12.6737 16.1737 14.1899C15.1263 15.706 13.5573 16.7834 11.7661 17.2166C9.97501 17.6498 8.08709 17.4084 6.46258 16.5384C4.83807 15.6685 3.59068 14.231 2.95841 12.5001M2.54175 16.6667V12.5001H6.70841"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default History;
