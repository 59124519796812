export const EVENT_NAME = {
  PURCHASE_ORDER_INITIATED: "Purchase Order Initiated",
  PURCHASE_ORDER_VERIFIED: "Purchase Order Verified",
  PROFORMA_INVOICE_INITIATED: "Proforma Invoice Initiated",
  PROFORMA_INVOICE_REVIEWED: "Proforma Invoice Reviewed",
  PROFORMA_INVOICE_VERIFIED: "Proforma Invoice Verified",
  AGREEMENT: "Agreement",
  MANUFACTURING: "Manufacturing",
  QC_INITIATED: "QC Initiated",
  QC_COMPLETED: "QC Completed",
  DELIVERY_INITIATED: "Delivery Initiated",
  DELIVERY_VERIFIED: "Delivery Verified",
  INVOICE_INITIATED: "Invoice initiated",
  INVOICE_VERIFIED: "Invoice verified",
  PAYMENT_INITIATED: "Payment Initiated",
  PAYMENT_VERIFIED: "Payment Verified",
  ARRIVAL_INITIATED: "Arrival Initiated",
  ARRIVAL_VERIFIED: "Arrival Verified",
  SETTLEMENT_INITIATED: "Settlement Initiated",
  SETTLEMENT_VERIFIED: "Settlement Verified"
};

export const EVENT_CODE = {
  PURCHASE_ORDER_INITIATED: "1-1",
  PURCHASE_ORDER_VERIFIED: "1-2",
  PROFORMA_INVOICE_INITIATED: "2-1",
  PROFORMA_INVOICE_REVIEWED: "2-2",
  PROFORMA_INVOICE_VERIFIED: "2-3",
  Agreement: "3",
  MANUFACTURING: "offchain",
  QC_INITIATED: "4-1",
  QC_COMPLETED: "4-2",
  DELIVERY_INITIATED: "5-1",
  DELIVERY_VERIFIED: "5-2",
  INVOICE_INITIATED: "6-1",
  INVOICE_VERIFIED: "6-2",
  PAYMENT_INITIATED: "7-1",
  PAYMENT_VERIFIED: "7-2",
  ARRIVAL_INITIATED: "8-1",
  ARRIVAL_VERIFIED: "8-2",
  SETTLEMENT_INITIATED: "9-1",
  SETTLEMENT_VERIFIED: "9-2"
};

export const ROLE_CODE = {
  SUPER_ADMIN: "SUA",
  ADMIN: "AD",
  SALES_AGENT: "AG",
  PROCUREMENT: "PC",
  QUALITY_CONTROL: "QC",
  FINANCE: "FN",
  SUPPLIER: "SP",
  SUPPLIER_QC: "SQC",
  BUYER: "BY",
  FINANCIERS: "FNS"
};

export const ROLE_NAME = {
  SUA: "Super Admin",
  AD: "Admin",
  AG: "Sales Agent",
  PC: "Procurement",
  QC: "Quality Control",
  FN: "Finance",
  SP: "Supplier",
  SQC: "Supplier QC",
  BY: "Buyer",
  FNS: "Financiers"
};

export const ENDPOINT = {
  MARLIN_URL: process.env.REACT_APP_API_MARLIN,
  FILE_MANAGEMENT_URL: process.env.REACT_APP_API_FILE_MANAGEMENT_URL,
  KEY_MAP: process.env.REACT_APP_KEY_GOOGLEMAPS,
  STORAGE_URL: process.env.REACT_APP_STORAGE_URL,
  API_TRACEABILITY: process.env.REACT_APP_API_TRACEABILITY_URL
};

export const VARIABLE_REGEX = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  phone: /^[0-9]/,
  npwn: /^[0-9].{14,}/
};
