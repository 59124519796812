import service from "./service";
import endpoint from "./constantUrl";

// Guest
export const requestGuestCode = (data) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/guest/request`, data);
};

export const getGuestCode = (code) => {
  return service.get(`${endpoint.MARLIN_URL}/v1/guest/${code}`);
};
