import React from "react";

function UserGroup({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.1667 16.6666H18.3334V14.9999C18.3334 13.6192 17.2141 12.4999 15.8334 12.4999C15.037 12.4999 14.3276 12.8723 13.8698 13.4523M14.1667 16.6666H5.83335M14.1667 16.6666V14.9999C14.1667 14.4531 14.0613 13.9308 13.8698 13.4523M5.83335 16.6666H1.66669V14.9999C1.66669 13.6192 2.78598 12.4999 4.16669 12.4999C4.96303 12.4999 5.6724 12.8723 6.13023 13.4523M5.83335 16.6666V14.9999C5.83335 14.4531 5.93871 13.9308 6.13023 13.4523M6.13023 13.4523C6.74461 11.9174 8.2457 10.8333 10 10.8333C11.7543 10.8333 13.2554 11.9174 13.8698 13.4523M12.5 5.83325C12.5 7.21396 11.3807 8.33325 10 8.33325C8.61931 8.33325 7.50002 7.21396 7.50002 5.83325C7.50002 4.45254 8.61931 3.33325 10 3.33325C11.3807 3.33325 12.5 4.45254 12.5 5.83325ZM17.5 8.33325C17.5 9.25373 16.7538 9.99992 15.8334 9.99992C14.9129 9.99992 14.1667 9.25373 14.1667 8.33325C14.1667 7.41278 14.9129 6.66659 15.8334 6.66659C16.7538 6.66659 17.5 7.41278 17.5 8.33325ZM5.83335 8.33325C5.83335 9.25373 5.08716 9.99992 4.16669 9.99992C3.24621 9.99992 2.50002 9.25373 2.50002 8.33325C2.50002 7.41278 3.24621 6.66659 4.16669 6.66659C5.08716 6.66659 5.83335 7.41278 5.83335 8.33325Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserGroup;
