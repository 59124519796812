import { combineReducers } from "redux";

// Import reducer from each folder
import auth from "./auth/reducer";
import history from "./history/reducer";
import activity from "./activity/reducer";
import contract from "./contract/reducer";
import trade from "./trade/reducer";
import user from "./user/reducer";
import general from "./general/reducer";
import help from "./help/reducer";
import guest from "./guest/reducer";
import traceability from "./traceability/reducer";
import catchingArea from "./catchingArea/reducer";
import role from "./role/reducer";
import userLevel from "./userLevel/reducer";
import dashboard from "./dashboard/reducer";
import traceabilityAccount from "./traceabilityAccount/reducer";
import company from "./company/reducer";

const createRootReducer = () =>
  combineReducers({
    // List reducer
    auth,
    history,
    activity,
    contract,
    trade,
    user,
    general,
    help,
    guest,
    traceability,
    catchingArea,
    role,
    userLevel,
    dashboard,
    traceabilityAccount,
    company
  });

export default createRootReducer;
