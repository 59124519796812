// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-wrapper {
  height: 100px;
  width: 800px;
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)); }

.loading-element {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center; }

.loading-element:nth-child(1) {
  animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 0.5s both; }

.loading-element:nth-child(2) {
  animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 1.5s both; }

.loading-element:nth-child(3) {
  animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 2.5s both; }

@keyframes animate {
  to {
    margin-top: -100px; } }
`, "",{"version":3,"sources":["webpack://./src/assets/styles/loading.scss"],"names":[],"mappings":"AACE;EAAA,aAAM;EAAN,YAAM;EAAN,gBAAM;EAAN,kBAAM;EAAN,2DAAM;;AAIN;EAAA,aAAM;EAAN,YAAM;EAAN,WAAM;EAAN,mBAAM;EAAN,yBAAM;;AAGR;EACI,8DAA8D,EAAA;;AAGlE;EACI,8DAA8D,EAAA;;AAGlE;EACI,8DAA8D,EAAA;;AAGlE;EACE;IACE,kBAAkB,EAAA,EAAA","sourcesContent":[".loading-wrapper {\n  @apply h-[100px] w-[800px] overflow-hidden bg-white;\n}\n\n.loading-element {\n  @apply flex h-full w-full items-center justify-center;\n}\n\n.loading-element:nth-child(1){\n    animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 0.5s both;\n}\n\n.loading-element:nth-child(2){\n    animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 1.5s both;\n}\n\n.loading-element:nth-child(3){\n    animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 2.5s both;\n}\n\n@keyframes animate {\n  to {\n    margin-top: -100px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
