import service from "./service";
import endpoint from "./constantUrl";

// Activity
export const getActivityOverview = () => {
  return service.get(`${endpoint.MARLIN_URL}/v1/activities/overview`);
};

export const getActivity = (param) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/activities/list`, param);
};

export const addActivity = (data) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/activities`, data);
};

export const getActivityById = (id) => {
  return service.get(`${endpoint.MARLIN_URL}/v1/activities/${id}`);
};
