import { all, put, takeLatest, call } from "redux-saga/effects";
import { getCatchingArea } from "../../../services/catchingArea";
import { SET_STATE, GET_CATCHING_AREA } from "./actions";

export function* GET_CATCHING_AREA_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getCatchingArea, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(GET_CATCHING_AREA, GET_CATCHING_AREA_F)]);
