import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  getActivityHistory,
  getTraceabilityHistoryNew
} from "../../../services/history";
import {
  SET_STATE,
  GET_ACTIVITY_HISTORY,
  GET_TRACEABILITY_HISTORY
} from "./actions";

export function* GET_ACTIVITY_HISTORY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getActivityHistory, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TRACEABILITY_HISTORY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTraceabilityHistoryNew, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_ACTIVITY_HISTORY, GET_ACTIVITY_HISTORY_F),
  takeLatest(GET_TRACEABILITY_HISTORY, GET_TRACEABILITY_HISTORY_F)
]);
