import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  getAllDoc,
  getContract,
  getContractById
} from "../../../services/contract";
import {
  SET_STATE,
  GET_CONTRACT,
  GET_CONTRACT_BY_ID,
  GET_ALL_DOC
} from "./actions";

export function* GET_CONTRACT_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getContract, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_CONTRACT_BY_ID_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getContractById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_ALL_DOC_F(action) {
  yield put({ type: SET_STATE, payload: { loadingDoc: true, dataDoc: null } });
  try {
    const { data } = yield call(getAllDoc, action.id);
    yield put({ type: SET_STATE, payload: { dataDoc: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDoc: false } });
  }
}

export default all([
  takeLatest(GET_CONTRACT, GET_CONTRACT_F),
  takeLatest(GET_CONTRACT_BY_ID, GET_CONTRACT_BY_ID_F),
  takeLatest(GET_ALL_DOC, GET_ALL_DOC_F)
]);
