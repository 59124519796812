import service from "./service";
import endpoint from "./constantUrl";

export const getRole = (param) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/roles/list`, param);
};

export const getRoleById = (id) => {
  return service.get(`${endpoint.MARLIN_URL}/v1/roles/` + id);
};

export const addRole = (data) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/roles`, data);
};

export const updateRole = (data, uuid) => {
  return service.put(`${endpoint.MARLIN_URL}/v1/roles/` + uuid, data);
};

export const deleteRole = (uuid) => {
  return service.delete(`${endpoint.MARLIN_URL}/v1/roles/` + uuid);
};
