import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  getUserSupplierList,
  getUser,
  addUser,
  getUserByUUID,
  updateUser,
  deleteUser,
  activateOrDeactivateUser,
  changePhotoUser
} from "../../../services/user";
import {
  SET_STATE,
  GET_USER_SUPPLIER_LIST,
  GET_USER,
  ADD_USER,
  GET_USER_BY_UUID,
  UPDATE_USER,
  DELETE_USER,
  ACTIVATE_OR_DEACTIVATE_USER,
  CHANGE_PHOTO_USER
} from "./actions";

export function* GET_USER_SUPPLIER_LIST_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getUserSupplierList, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_USER_F(action) {
  yield put({ type: SET_STATE, payload: { loadingList: true } });
  try {
    const { data } = yield call(getUser, action.param);
    yield put({ type: SET_STATE, payload: { list: data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingList: false } });
  }
}

export function* GET_USER_BY_UUID_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getUserByUUID, action.uuid);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_USER_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(addUser, action.data);

    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* UPDATE_USER_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(updateUser, action.data, action.uuid);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* DELETE_USER_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(deleteUser, action.uuid);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* ACTIVATE_OR_DEACTIVATE_USER_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(activateOrDeactivateUser, action.uuid);
    yield put({
      type: SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* CHANGE_PHOTO_USER_F(action) {
  yield put({ type: SET_STATE, payload: { loadingCP: true } });
  try {
    const { data } = yield call(changePhotoUser, action.data);

    yield put({
      type: SET_STATE,
      payload: { isCP: true, message: data.message, dataCp: data.data }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingCP: false } });
  }
}

export default all([
  takeLatest(GET_USER_SUPPLIER_LIST, GET_USER_SUPPLIER_LIST_F),
  takeLatest(GET_USER, GET_USER_F),
  takeLatest(GET_USER_BY_UUID, GET_USER_BY_UUID_F),
  takeLatest(ADD_USER, ADD_USER_F),
  takeLatest(UPDATE_USER, UPDATE_USER_F),
  takeLatest(DELETE_USER, DELETE_USER_F),
  takeLatest(ACTIVATE_OR_DEACTIVATE_USER, ACTIVATE_OR_DEACTIVATE_USER_F),
  takeLatest(CHANGE_PHOTO_USER, CHANGE_PHOTO_USER_F)
]);
