import service from "./service";
import endpoint from "./constantUrl";

// Auth
export const login = (data) => {
  return service.postWithoutHeader(
    `${endpoint.MARLIN_URL}/v1/auth/login`,
    data
  );
};

export const createTokenForgotPassword = (data) => {
  return service.postWithoutHeader(
    `${endpoint.MARLIN_URL}/v1/auth/forgot-password-verify`,
    data
  );
};

export const verifyTokenForgotPassword = (data) => {
  return service.postWithoutHeader(
    `${endpoint.MARLIN_URL}/v1/auth/forgot-password-token-verify`,
    data
  );
};

export const submitForgotPassword = (data) => {
  return service.postWithoutHeader(
    `${endpoint.MARLIN_URL}/v1/auth/forgot-password`,
    data
  );
};

export const verifyTokenUserCreation = (data) => {
  return service.postWithoutHeader(
    `${endpoint.MARLIN_URL}/v1/users/user-creation-token-verify`,
    data
  );
};

export const submitUserCreation = (data) => {
  return service.postWithoutHeader(
    `${endpoint.MARLIN_URL}/v1/users/user-creation-verify`,
    data
  );
};
