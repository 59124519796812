import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "userLevel/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_USER_LEVEL = "GET_USER_LEVEL";
export const getUserLevel = defaultActionCreator(GET_USER_LEVEL, "param");

export const GET_USER_LEVEL_BY_ID = "GET_USER_LEVEL_BY_ID";
export const getUseLevelrById = defaultActionCreator(
  GET_USER_LEVEL_BY_ID,
  "id"
);

export const ADD_USER_LEVEL = "ADD_USER_LEVEL";
export const addUserLevel = defaultActionCreator(ADD_USER_LEVEL, "data");

export const UPDATE_USER_LEVEL = "UPDATE_USER_LEVEL";
export const updateUserLevel = defaultActionCreator(
  UPDATE_USER_LEVEL,
  "data",
  "id"
);

export const DELETE_USER_LEVEL = "DELETE_USER_LEVEL";
export const deleteUserLevel = defaultActionCreator(DELETE_USER_LEVEL, "id");
