import service from "./service";
import endpoint from "./constantUrl";

// User
export const getUserSupplierList = (param) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/users/list-supplier`, param);
};

export const getUser = (param) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/users/list`, param);
};

export const getUserByUUID = (uuid) => {
  return service.get(`${endpoint.MARLIN_URL}/v1/users/` + uuid);
};

export const addUser = (data) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/users`, data);
};

export const updateUser = (data, uuid) => {
  return service.put(`${endpoint.MARLIN_URL}/v1/users/` + uuid, data);
};

export const deleteUser = (uuid) => {
  return service.delete(`${endpoint.MARLIN_URL}/v1/users/` + uuid);
};

export const activateOrDeactivateUser = (uuid) => {
  return service.put(
    `${endpoint.MARLIN_URL}/v1/users/activate-deactivate/` + uuid
  );
};

export const changePhotoUser = (data) => {
  return service.post(`${endpoint.MARLIN_URL}/v1/users/change-picture`, data);
};
