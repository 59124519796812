import React from "react";

function Mail({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.5 5.83329C2.5 5.39127 2.67559 4.96734 2.98816 4.65478C3.30072 4.34222 3.72464 4.16663 4.16667 4.16663H15.8333C16.2754 4.16663 16.6993 4.34222 17.0118 4.65478C17.3244 4.96734 17.5 5.39127 17.5 5.83329M2.5 5.83329V14.1666C2.5 14.6087 2.67559 15.0326 2.98816 15.3451C3.30072 15.6577 3.72464 15.8333 4.16667 15.8333H15.8333C16.2754 15.8333 16.6993 15.6577 17.0118 15.3451C17.3244 15.0326 17.5 14.6087 17.5 14.1666V5.83329M2.5 5.83329L10 10.8333L17.5 5.83329"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Mail;
