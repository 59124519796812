import React from "react";

function Bell({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.49998 14.1667V15C7.49998 15.663 7.76337 16.2989 8.23221 16.7678C8.70105 17.2366 9.33694 17.5 9.99998 17.5C10.663 17.5 11.2989 17.2366 11.7677 16.7678C12.2366 16.2989 12.5 15.663 12.5 15V14.1667M8.33331 4.16667C8.33331 3.72464 8.50891 3.30072 8.82147 2.98816C9.13403 2.67559 9.55795 2.5 9.99998 2.5C10.442 2.5 10.8659 2.67559 11.1785 2.98816C11.4911 3.30072 11.6666 3.72464 11.6666 4.16667C12.6237 4.61919 13.4395 5.32361 14.0267 6.20442C14.6139 7.08523 14.9503 8.10923 15 9.16667V11.6667C15.0627 12.1848 15.2462 12.6809 15.5357 13.1151C15.8251 13.5493 16.2125 13.9095 16.6666 14.1667H3.33331C3.78743 13.9095 4.17482 13.5493 4.4643 13.1151C4.75378 12.6809 4.93727 12.1848 4.99998 11.6667V9.16667C5.04961 8.10923 5.38606 7.08523 5.97327 6.20442C6.56048 5.32361 7.3763 4.61919 8.33331 4.16667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Bell;
