import React from "react";

function User({ className = "h-4 w-4" }) {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.49998 10C6.3866 10 4.50718 11.0204 3.31064 12.604C3.05312 12.9448 2.92436 13.1152 2.92857 13.3455C2.93182 13.5235 3.04356 13.7479 3.18356 13.8578C3.36477 14 3.61589 14 4.11814 14H12.8818C13.3841 14 13.6352 14 13.8164 13.8578C13.9564 13.7479 14.0681 13.5235 14.0714 13.3455C14.0756 13.1152 13.9468 12.9448 13.6893 12.604C12.4928 11.0204 10.6134 10 8.49998 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49998 8C10.1568 8 11.5 6.65685 11.5 5C11.5 3.34315 10.1568 2 8.49998 2C6.84313 2 5.49998 3.34315 5.49998 5C5.49998 6.65685 6.84313 8 8.49998 8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default User;
