import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "guest/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const REQUEST_GUEST_CODE = "REQUEST_GUEST_CODE";
export const requestGuestCode = defaultActionCreator(
  REQUEST_GUEST_CODE,
  "data"
);

export const GET_GUEST_CODE = "GET_GUEST_CODE";
export const getGuestCode = defaultActionCreator(GET_GUEST_CODE, "code");
