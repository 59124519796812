import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "role/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

export const GET_ROLE = "GET_ROLE";
export const getRole = defaultActionCreator(GET_ROLE, "param");

export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const getRoleById = defaultActionCreator(GET_ROLE_BY_ID, "id");

export const ADD_ROLE = "ADD_ROLE";
export const addRole = defaultActionCreator(ADD_ROLE, "data");

export const UPDATE_ROLE = "UPDATE_ROLE";
export const updateRole = defaultActionCreator(UPDATE_ROLE, "data", "id");

export const DELETE_ROLE = "DELETE_ROLE";
export const deleteRole = defaultActionCreator(DELETE_ROLE, "id");
