import React, { useState } from "react";
import { useSelector } from "react-redux";

// Assets
import IconMail from "../../assets/icons/mail";
import IconBell from "../../assets/icons/bell";
import IconUser from "../../assets/icons/user";
import IconCheveronRight from "../../assets/icons/cheveronRight";
import IconMenu from "../../assets/icons/menu";
import IconProfile from "../../assets/icons/profile";
import LogoFishlog from "../../assets/images/logo fishlog sm.svg";

// Utils
import {
  removeLocalStorage,
  calculateDateDifference,
  getObjectLocalStorage
} from "../../utils";
import { ENDPOINT } from "../../utils/constants";

const Header = ({ openMenu, setOpenMenu, handleOpenNotif }) => {
  const user = getObjectLocalStorage("user");
  const auth = getObjectLocalStorage("auth");

  const [showMenuProfile, setShowMenuProfile] = useState(false);
  const [showNotif, setShowNotif] = useState(false);

  const general = useSelector((state) => state.general);
  const dataNotif = general?.listNotif;
  const countNotif = general?.countUnread;

  const handleLogout = () => {
    removeLocalStorage();
  };

  const handleClickNotif = () => {
    setShowNotif(!showNotif);
    if (countNotif > 0) handleOpenNotif();
  };

  return (
    <div className="fixed z-50 h-[80px] w-full bg-white shadow-DropShadow">
      <div className="flex h-full items-center justify-between px-8 py-5">
        <div className="flex items-center justify-center">
          <div
            className="me-3 cursor-pointer md:hidden"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <IconMenu className="h-8 w-8" />
          </div>

          <div className="flex items-center">
            <img src={LogoFishlog} alt="fishlog trace logo" className="me-3" />
            <h1 className="origin-left cursor-default text-xl font-SemiBold text-NetralTypography md:text-2xl">
              Trace Management System
            </h1>
          </div>
        </div>

        {auth?.code === undefined && user?.loginType === "blockchain" ? (
          <div className="flex space-x-4">
            <a
              href="/blockchain/help"
              className="cursor-pointer rounded-full border p-2.5"
            >
              <div className="flex w-full items-center justify-center">
                <IconMail />
              </div>
            </a>

            <div
              className={`relative cursor-pointer rounded-full border p-2.5 ${
                showNotif ? "border-Blue100 bg-Blue50 shadow-DropShadow2" : ""
              }`}
              onClick={handleClickNotif}
            >
              <div>
                <IconBell
                  className={`h-4 w-4 ${showNotif ? "text-BlueDark" : ""}`}
                />
                {countNotif > 0 && (
                  <div className="absolute right-1.5 top-1.5 h-3.5 w-3.5 rounded-full border bg-red-600">
                    <div className="flex h-full w-full items-center justify-center">
                      <p className="block text-xxs text-white">{countNotif}</p>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`delay-50 absolute -right-16 top-12 z-10 w-[180px] cursor-default rounded-lg bg-white shadow-Shadow1 transition duration-300 ease-in-out ${
                  showNotif
                    ? "scale-100"
                    : "-translate-y-[60%] translate-x-2 scale-0"
                } after:contents-[' '] after:absolute after:right-[76px] after:top-0 after:-z-10 after:h-4 after:w-4 after:origin-right after:rotate-45 after:rounded-sm after:bg-white`}
              >
                <p className="p-2 text-sm font-SemiBold">Notifications</p>

                <div className="max-h-[120px] overflow-y-auto">
                  {dataNotif?.results?.map((item, key) => (
                    <div
                      key={key}
                      className="mb-2 cursor-pointer px-2 py-1 hover:bg-Blue50"
                    >
                      <p className="text-left text-xs font-Reguler">
                        {item?.message?.message}
                      </p>
                      <p className="text-right text-xxs font-Reguler text-Grey500">
                        {calculateDateDifference(item?.timestamp)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div
                className={`cursor-pointer rounded-full border p-2.5 ${
                  showMenuProfile
                    ? "border-Blue100 bg-Blue50 shadow-DropShadow2"
                    : ""
                }`}
                onClick={() => setShowMenuProfile(!showMenuProfile)}
              >
                <IconUser
                  className={`h-4 w-4 ${
                    showMenuProfile ? "text-BlueDark" : ""
                  }`}
                />
              </div>

              <div
                className={`delay-50 absolute right-5 z-10 w-[180px] rounded-lg bg-white shadow-Shadow1 transition duration-300 ease-in-out ${
                  showMenuProfile
                    ? "translate-y-[10%] scale-100"
                    : "-translate-y-[60%] translate-x-[35%] scale-0"
                } after:contents-[' '] after:absolute after:right-6 after:top-0 after:h-4 after:w-4 after:origin-right after:rotate-45 after:rounded-sm after:bg-white`}
              >
                <ul className="py-2 text-Grey800">
                  <div className="mb-3 flex cursor-default items-center p-2">
                    <div className="me-3 flex h-10 w-10 items-center justify-center rounded-full bg-BackgroundPhoto">
                      {user?.picture ? (
                        <img
                          className="rounded-full"
                          src={`${ENDPOINT.STORAGE_URL}/attachment/${user?.picture}`}
                        />
                      ) : (
                        <IconProfile className="h-[90px] w-[90px] text-[#404E69]" />
                      )}
                    </div>
                    <div>
                      <p className="text-base font-SemiBold text-NetralTypography">
                        {user?.name}
                      </p>
                      <p className="text-xs font-Reguler text-Grey500">
                        {user?.role?.name || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="block cursor-pointer p-2 hover:bg-Blue50">
                    <a
                      href="/blockchain/profile"
                      className="flex w-full items-center justify-between"
                    >
                      <span className="text-sm font-Reguler">Profile</span>
                      <IconCheveronRight />
                    </a>
                  </div>
                  <div
                    className="block cursor-pointer p-2 hover:bg-Blue50"
                    onClick={() => handleLogout()}
                  >
                    <span className="text-sm font-Reguler">Log Out</span>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={`cursor-pointer rounded-full border p-2.5 ${
                showMenuProfile
                  ? "border-Blue100 bg-Blue50 shadow-DropShadow2"
                  : ""
              }`}
              onClick={() => setShowMenuProfile(!showMenuProfile)}
            >
              <IconUser
                className={`h-4 w-4 ${showMenuProfile ? "text-BlueDark" : ""}`}
              />
            </div>

            <div
              className={`delay-50 absolute right-5 z-10 w-[180px] rounded-lg bg-white shadow-Shadow1 transition duration-300 ease-in-out ${
                showMenuProfile
                  ? "translate-y-[10%] scale-100"
                  : "-translate-y-[60%] translate-x-[35%] scale-0"
              } after:contents-[' '] after:absolute after:right-6 after:top-0 after:h-4 after:w-4 after:origin-right after:rotate-45 after:rounded-sm after:bg-white`}
            >
              <ul className="py-2 text-Grey800">
                <div className="mb-3 flex cursor-default items-center p-2">
                  <div className="me-3 flex h-10 w-10 items-center justify-center rounded-full bg-BackgroundPhoto">
                    <IconProfile className="h-6 w-6" />
                  </div>
                  <div>
                    <p className="text-base font-SemiBold text-NetralTypography">
                      {user?.name}
                    </p>
                    <p className="text-xs font-Reguler text-Grey500">
                      {user?.loginType === "traceability"
                        ? "Admin Traceability"
                        : "Guest"}
                    </p>
                  </div>
                </div>

                <div
                  className="block cursor-pointer p-2 hover:bg-Blue50"
                  onClick={() => handleLogout()}
                >
                  <span className="text-sm font-Reguler">Log Out</span>
                </div>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
