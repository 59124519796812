import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Assets
import IconDashboard from "../../assets/icons/dashboard";
import IconHistory from "../../assets/icons/history";
import IconActivity from "../../assets/icons/activity";
import IconCountract from "../../assets/icons/contract";
import IconAccount from "../../assets/icons/user";
import IconQrCode from "../../assets/icons/qrCode";
import IconUser from "../../assets/icons/userPlus";
import IconUserGroup from "../../assets/icons/userGroup";
import IconCheveronDown from "../../assets/icons/cheveronDown";
import IconCheveronUp from "../../assets/icons/cheveronUp";
import IconMap from "../../assets/icons/map";
import IconCube from "../../assets/icons/cube";
import IconBuilding from "../../assets/icons/building";

// Utils
import { getObjectLocalStorage } from "../../utils";
import { ROLE_CODE } from "../../utils/constants";

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const user = getObjectLocalStorage("user");
  const auth = getObjectLocalStorage("auth");

  const [dropdown, setDropdown] = useState("");

  useEffect(() => {
    if (currentPath?.includes("account")) setDropdown("account");
    if (currentPath?.includes("company")) setDropdown("account");
    if (currentPath?.includes("code")) setDropdown("traceability");
  }, []);

  const handleDropdown = (v) => {
    if (dropdown !== "" && v === dropdown) {
      setDropdown("");
      return;
    }
    setDropdown(v);
  };

  return (
    <ul className="bg-white">
      {/* Dashboard */}
      <a
        href={`/${user?.loginType}/dashboard`}
        className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
          currentPath === "dashboard" ? "bg-Blue50 text-Blue600" : ""
        }`}
      >
        <span
          className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
        >
          <IconDashboard className="h-5 w-5" />
          <span className="ml-3 text-base font-Normal">{"Dashboard"}</span>
        </span>
      </a>

      {user?.loginType === "blockchain" ? (
        <>
          {/* History */}
          <a
            href={"/blockchain/history"}
            className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
              currentPath === "history" ? "bg-Blue50 text-Blue600" : ""
            }`}
          >
            <span
              className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
            >
              <IconHistory className="h-5 w-5" />
              <span className="ml-3 text-base font-Normal">{"History"}</span>
            </span>
          </a>

          {/* Activity */}
          {auth?.code === undefined && (
            <a
              href={"/blockchain/activity"}
              className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                currentPath === "activity" ? "bg-Blue50 text-Blue600" : ""
              }`}
            >
              <span
                className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
              >
                <IconActivity className="h-5 w-5" />
                <span className="ml-3 text-base font-Normal">{"Activity"}</span>
              </span>
            </a>
          )}

          {/* Contract */}
          <a
            href={"/blockchain/contract"}
            className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
              currentPath === "contract" ? "bg-Blue50 text-Blue600" : ""
            }`}
          >
            <span
              className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
            >
              <IconCountract className="h-5 w-5" />
              <span className="ml-3 text-base font-Normal">{"Contract"}</span>
            </span>
          </a>

          {/* Account */}
          {user?.user_role === ROLE_CODE.SUPER_ADMIN && (
            <>
              <button
                onClick={() => handleDropdown("account")}
                className={`group relative mb-2 flex h-auto w-full items-center justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                  currentPath?.includes("account") && dropdown !== "account"
                    ? "bg-Blue50 text-Blue600"
                    : ""
                }`}
              >
                <span
                  className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                >
                  <IconAccount className="h-5 w-5" />
                  <span className="ml-3 text-base font-Normal">
                    {"Account"}
                  </span>
                </span>
                <span className="ml-auto">
                  {dropdown === "account" ? (
                    <IconCheveronUp className="h-5 w-5" />
                  ) : (
                    <IconCheveronDown className="h-5 w-5" />
                  )}
                </span>
              </button>

              <div
                className={`overflow-hidden transition-all delay-150 duration-300 ${dropdown === "account" ? "mb-2 h-36" : "mb-0 h-0"}`}
              >
                <ul className="ml-8">
                  <li>
                    <a
                      href={"/blockchain/account-user"}
                      className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                        currentPath === "account-user"
                          ? "bg-Blue50 text-Blue600"
                          : ""
                      }`}
                    >
                      <span
                        className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                      >
                        <IconUser className="h-5 w-5" />
                        <span className="ml-3 text-base font-Normal">
                          {"User"}
                        </span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={"/blockchain/account-role"}
                      className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                        currentPath === "account-role"
                          ? "bg-Blue50 text-Blue600"
                          : ""
                      }`}
                    >
                      <span
                        className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                      >
                        <IconUserGroup className="h-5 w-5" />
                        <span className="ml-3 text-base font-Normal">
                          {"Role"}
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      ) : user?.loginType === "traceability" ? (
        <>
          {/* Traceablity */}
          <button
            onClick={() => handleDropdown("traceability")}
            className={`group relative mb-2 flex h-auto w-full items-center justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
              currentPath?.includes("traceability") &&
              dropdown !== "traceability"
                ? "bg-Blue50 text-Blue600"
                : ""
            }`}
          >
            <span
              className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
            >
              <IconQrCode className="h-5 w-5" />
              <span className="ml-3 text-base font-Normal">
                {"Traceability"}
              </span>
            </span>
            <span className="ml-auto">
              {dropdown === "traceability" ? (
                <IconCheveronUp className="h-5 w-5" />
              ) : (
                <IconCheveronDown className="h-5 w-5" />
              )}
            </span>
          </button>

          <div
            className={`overflow-hidden transition-all delay-150 duration-300 ${dropdown === "traceability" ? "mb-2 h-36" : "mb-0 h-0"}`}
          >
            <ul className="ml-8">
              <li>
                <a
                  href={"/traceability/traceability-code"}
                  className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                    currentPath === "traceability-code"
                      ? "bg-Blue50 text-Blue600"
                      : ""
                  }`}
                >
                  <span
                    className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                  >
                    <IconMap className="h-5 w-5" />
                    <span className="ml-3 text-base font-Normal">
                      {"Traceability Code"}
                    </span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  href={"/traceability/production-code"}
                  className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                    currentPath === "production-code"
                      ? "bg-Blue50 text-Blue600"
                      : ""
                  }`}
                >
                  <span
                    className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                  >
                    <IconCube className="h-5 w-5" />
                    <span className="ml-3 text-base font-Normal">
                      {"Production Code"}
                    </span>
                  </span>
                </a>
              </li>
            </ul>
          </div>

          {/* History */}
          <a
            href={"/traceability/history"}
            className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
              currentPath === "history" ? "bg-Blue50 text-Blue600" : ""
            }`}
          >
            <span
              className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
            >
              <IconHistory className="h-5 w-5" />
              <span className="ml-3 text-base font-Normal">{"History"}</span>
            </span>
          </a>

          {/* Account */}
          {(user?.role_id === 1 || user?.role_id === 3) && (
            <>
              <button
                onClick={() => handleDropdown("account")}
                className={`group relative mb-2 flex h-auto w-full items-center justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                  currentPath?.includes("account") && dropdown !== "account"
                    ? "bg-Blue50 text-Blue600"
                    : ""
                }`}
              >
                <span
                  className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                >
                  <IconAccount className="h-5 w-5" />
                  <span className="ml-3 text-base font-Normal">
                    {"Account"}
                  </span>
                </span>
                <span className="ml-auto">
                  {dropdown === "account" ? (
                    <IconCheveronUp className="h-5 w-5" />
                  ) : (
                    <IconCheveronDown className="h-5 w-5" />
                  )}
                </span>
              </button>

              <div
                className={`overflow-hidden transition-all delay-150 duration-300 ${dropdown === "account" ? "mb-2 h-36" : "mb-0 h-0"}`}
              >
                <ul className="ml-8">
                  {user?.role_id === 1 && (
                    <li>
                      <a
                        href={"/traceability/company"}
                        className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                          currentPath === "company"
                            ? "bg-Blue50 text-Blue600"
                            : ""
                        }`}
                      >
                        <span
                          className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                        >
                          <IconBuilding className="h-5 w-5" />
                          <span className="ml-3 text-base font-Normal">
                            {"Company"}
                          </span>
                        </span>
                      </a>
                    </li>
                  )}

                  <li>
                    <a
                      href={"/traceability/account"}
                      className={`group relative mb-2 flex h-auto w-full justify-start self-center rounded-lg px-5 py-2.5 hover:bg-Blue50 hover:text-Blue600 ${
                        currentPath === "account"
                          ? "bg-Blue50 text-Blue600"
                          : ""
                      }`}
                    >
                      <span
                        className={`group-hover:text-blue600 text-blue600 inline-flex items-center text-sm`}
                      >
                        <IconUser className="h-5 w-5" />
                        <span className="ml-3 text-base font-Normal">
                          {"Account"}
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </ul>
  );
};

export default Sidebar;
