import { all, put, takeLatest, call } from "redux-saga/effects";
import * as actions from "./actions";
import * as services from "../../../services/userLevel";

export function* GET_USER_LEVEL_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingList: true } });
  try {
    const { data } = yield call(services.getUserLevel, action.param);
    yield put({ type: actions.SET_STATE, payload: { list: data } });
  } catch ({ response }) {
    yield put({ type: actions.SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingList: false } });
  }
}

export function* GET_USER_LEVEL_BY_ID_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(services.getUserLevelById, action.uuid);
    yield put({ type: actions.SET_STATE, payload: { data } });
  } catch ({ response }) {
    yield put({ type: actions.SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_USER_LEVEL_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(services.addUserLevel, action.data);
    yield put({
      type: actions.SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: { error: error.response.data }
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* UPDATE_USER_LEVEL_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(
      services.updateUserLevel,
      action.data,
      action.uuid
    );
    yield put({
      type: actions.SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: { error: error.response.data }
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingChanges: false } });
  }
}

export function* DELETE_USER_LEVEL_F(action) {
  yield put({ type: actions.SET_STATE, payload: { loadingChanges: true } });
  try {
    const { data } = yield call(services.deleteUserLevel, action.uuid);
    yield put({
      type: actions.SET_STATE,
      payload: { isChanges: true, message: data.message }
    });
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: { error: error.response.data }
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loadingChanges: false } });
  }
}

export default all([
  takeLatest(actions.GET_USER_LEVEL, GET_USER_LEVEL_F),
  takeLatest(actions.GET_USER_LEVEL_BY_ID, GET_USER_LEVEL_BY_ID_F),
  takeLatest(actions.ADD_USER_LEVEL, ADD_USER_LEVEL_F),
  takeLatest(actions.UPDATE_USER_LEVEL, UPDATE_USER_LEVEL_F),
  takeLatest(actions.DELETE_USER_LEVEL, DELETE_USER_LEVEL_F)
]);
