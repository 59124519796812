import { defaultActionCreator } from "../../actionCreator";

export const SET_STATE = "traceability/SET_STATE";
export const setState = defaultActionCreator(SET_STATE, "payload");

// Traceable
export const GET_TRACEABLE = "GET_TRACEABLE";
export const getTraceable = defaultActionCreator(GET_TRACEABLE, "param");

export const ADD_TRACEABLE = "ADD_TRACEABLE";
export const addTraceable = defaultActionCreator(ADD_TRACEABLE, "data");

export const GET_TRACEABLE_BY_ID = "GET_TRACEABLE_BY_ID";
export const getTraceableById = defaultActionCreator(GET_TRACEABLE_BY_ID, "id");

export const DETELE_TRACEABLE = "DETELE_TRACEABLE";
export const deleteTraceable = defaultActionCreator(DETELE_TRACEABLE, "id");

export const DOWNLOAD_TEMPLATE_TRACEABLE = "DOWNLOAD_TEMPLATE_TRACEABLE";
export const downloadTemplateTraceable = defaultActionCreator(
  DOWNLOAD_TEMPLATE_TRACEABLE
);

export const UPLOAD_TRACEABLE = "UPLOAD_TRACEABLE";
export const uploadTraceable = defaultActionCreator(UPLOAD_TRACEABLE, "data");

export const GET_PROGRESS_UPLOAD_TRACEABLE = "GET_PROGRESS_UPLOAD_TRACEABLE";
export const getPregressUploadTraceable = defaultActionCreator(
  GET_PROGRESS_UPLOAD_TRACEABLE
);

// Traceability
export const GET_TRACEABILITY = "GET_TRACEABILITY";
export const getTraceability = defaultActionCreator(GET_TRACEABILITY, "param");

export const ADD_TRACEABILITY = "ADD_TRACEABILITY";
export const addTraceability = defaultActionCreator(ADD_TRACEABILITY, "data");

export const GET_TRACEABILITY_BY_CODE = "GET_TRACEABILITY_BY_CODE";
export const getTraceabilityByCode = defaultActionCreator(
  GET_TRACEABILITY_BY_CODE,
  "code"
);

export const GET_TRACEABILITY_BY_ID = "GET_TRACEABILITY_BY_ID";
export const getTraceabilityById = defaultActionCreator(
  GET_TRACEABILITY_BY_ID,
  "id"
);

export const DETELE_TRACEABILITY = "DETELE_TRACEABILITY";
export const deleteTraceability = defaultActionCreator(
  DETELE_TRACEABILITY,
  "id"
);

export const DOWNLOAD_TEMPLATE_TRACEABILITY = "DOWNLOAD_TEMPLATE_TRACEABILITY";
export const downloadTemplateTraceability = defaultActionCreator(
  DOWNLOAD_TEMPLATE_TRACEABILITY
);

export const UPLOAD_TRACEABILITY = "UPLOAD_TRACEABILITY";
export const uploadTraceability = defaultActionCreator(
  UPLOAD_TRACEABILITY,
  "data"
);

// Certification
export const GET_CERTIFICATION = "GET_CERTIFICATION";
export const getCertification = defaultActionCreator(
  GET_CERTIFICATION,
  "param"
);
